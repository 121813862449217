import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import * as SocialIcons from "components/SocialIcons";

import "./Footer.scss";

const Footer = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    copyright,
    social: { facebook },
  } = frontmatter;

  return (
    <footer className="footer py-2">
      <Container>
        <Row className="align-items-center text-center">
          <Col lg={5} className="text-lg-left">
            {copyright}
          </Col>
          <Col lg={3} className="mt-2 my-lg-0">
            {facebook ? <SocialIcons.Facebook userName={facebook} /> : null}
          </Col>
          <Col lg={3} style={{ fontSize: "10px" }} className="mt-2 my-lg-0">
            <a href="/3rd-party-licenses">Third party licenses</a>
          </Col>
          {/* <Col lg={4} className="text-lg-right">
              <a className="mr-3" href={privacyHref}>
                {privacyText}
              </a>
              <a href={termsHref}>{termsText}</a>
            </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
