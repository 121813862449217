import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

import useWindowOnScroll from "hooks/useWindowOnScroll";
import Icon from "components/Icon";
import NavItem from "components/NavItem/NavItem";
import NavDropdownItem from "components/NavDropdownItem/NavDropdownItem";
import getUrl from "utils/getBaseUrl";

import "./Navbar.scss";
import { Link } from "gatsby";

const MyNavbar = ({
  anchors,
  frontmatter,
  extraItems,
  isHome,
  pathContext: { defaultLang, langKey },
}) => {
  const { brand, pages, menuText } = frontmatter;

  const [expanded, setExpanded] = React.useState(false);
  const toggleMenu = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const closeMenu = React.useCallback(() => {
    setExpanded(false);
  }, []);

  const [shrink, setShrink] = React.useState(false);
  const handleWindowScroll = React.useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setShrink(scrollTop > 100);
  }, []);
  useWindowOnScroll(handleWindowScroll);

  const pageItems = (
    <Nav.Item>
      <Link
        className="page-item nav-link"
        to={getUrl("/gallery/", defaultLang, langKey)}
        activeClassName="active"
      >
        {pages && pages.gallery}
      </Link>
    </Nav.Item>
  );

  extraItems = null;

  return (
    <Navbar
      className={clsx("navbar-root", { "navbar-shrink": shrink })}
      expand="lg"
      fixed="top"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand className="cursor-pointer" onClick={closeMenu}>
          <Link to="/">{brand}</Link>
        </Navbar.Brand>
        <Navbar.Toggle onClick={toggleMenu} aria-label="Toggle navigation">
          {menuText}
          <Icon iconName="BarsIcon" />
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Nav className="text-uppercase ml-auto">
            {anchors.slice(0, 1).map((anchor) =>
              isHome ? (
                <NavItem key={anchor} to={anchor} onClick={closeMenu} />
              ) : (
                <Nav.Item key={`anchor ${anchor}`}>
                  <Link className="nav-link" to={getUrl(`/#${anchor}`, defaultLang, langKey)}>
                    {anchor}
                  </Link>
                </Nav.Item>
              ),
            )}
            {pages ? (
              <NavDropdown title={pages.products} id="services-nav">
                <NavDropdownItem
                  key="/aluminieva-dograma"
                  to={getUrl(`/aluminieva-dograma`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.aluminiumWindows || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/pvc-dograma"
                  to={getUrl(`/pvc-dograma`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.pvcWindows || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/vhodni-vrati"
                  to={getUrl(`/vhodni-vrati`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.frontDoors || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/vertikalni-shtori"
                  to={getUrl(`/vertikalni-shtori`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.verticalBlinds || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/rolo-shtori"
                  to={getUrl(`/rolo-shtori`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.rollerBlinds || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/roletni-shtori"
                  to={getUrl(`/roletni-shtori`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.externalBlinds || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/ohranitelni-roletki"
                  to={getUrl(`/ohranitelni-roletki`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.securityShutters || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/sekcionni-vrati"
                  to={getUrl(`/sekcionni-vrati`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.sectionalDoors || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/interiorni-vrati"
                  to={getUrl(`/interiorni-vrati`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.interiorDoors || ""}
                </NavDropdownItem>
                <NavDropdownItem
                  key="/sennici-tenti"
                  to={getUrl(`/sennici-tenti`, defaultLang, langKey)}
                  onClick={closeMenu}
                >
                  {pages.sunshades || ""}
                </NavDropdownItem>
              </NavDropdown>
            ) : null}
            {anchors.slice(2).map((anchor) =>
              isHome ? (
                <NavItem key={anchor} to={anchor} onClick={closeMenu} />
              ) : (
                <Nav.Item key={`anchor ${anchor}`}>
                  <Link className="nav-link" to={getUrl(`/#${anchor}`, defaultLang, langKey)}>
                    {anchor}
                  </Link>
                </Nav.Item>
              ),
            )}
            {pageItems}
          </Nav>
          {extraItems}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

MyNavbar.propTypes = {
  anchors: PropTypes.arrayOf(PropTypes.string),
  frontmatter: PropTypes.object,
  extraItems: PropTypes.any,
  isHome: PropTypes.bool,
  pathContext: PropTypes.object,
};

MyNavbar.defaultProps = {
  anchors: [],
  frontmatter: {},
  extraItems: null,
  isHome: false,
  pathContext: {
    langKey: "bg",
    defaultLang: "bg",
    langTextMap: {
      en: "English",
      bg: "Български",
    },
  },
};

export default MyNavbar;
