import React from "react";
import PropTypes from "prop-types";

import { NavDropdown } from "react-bootstrap";
import { Link } from "gatsby";

import "./NavDropdownItem.scss";

const NavDropdownItem = ({ to, onClick, children }) => {
  return (
    <NavDropdown.Item
      as={Link}
      className="nav-dropdown-link cursor-pointer"
      to={to}
      smooth="easeInOutQuart"
      onClick={onClick}
    >
      {children || to}
    </NavDropdown.Item>
  );
};

NavDropdownItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

NavDropdownItem.defaultProps = {
  to: "",
  onClick: null,
  children: null,
};

export default NavDropdownItem;
