import { curry, pathSatisfies, test, identity, path } from "ramda";

const propFilter = curry((pathList, regex) => pathSatisfies(test(regex), pathList));

/**
 * break down all data retrieved in index.js
 */
export default function breakDownAllNodes(nodes) {
  const filterByFileName = propFilter(["fields", "fileName"]);
  const filterByDirectoryName = propFilter(["fields", "directoryName"]);
  // top part
  const topNode = nodes.find(filterByFileName(/top/i)) || {};
  // navbar
  const navBarNode = nodes.find(filterByFileName(/navbar/i)) || {};
  // footer
  const footerNode = nodes.find(filterByFileName(/footer/i)) || {};
  // gallery
  const gallery = nodes.find(filterByFileName(/gallery/i)) || {};
  // aluminium windows
  const aluminiumWindows = nodes.find(filterByFileName(/aluminiumWindows/i)) || {};
  // pvc dograma
  const pvcWindows = nodes.find(filterByFileName(/pvcWindows/i)) || {};
  // front doors
  const frontDoors = nodes.find(filterByFileName(/FrontDoors/i)) || {};
  // vertical blinds
  const verticalBlinds = nodes.find(filterByFileName(/verticalBlinds/i)) || {};
  // roller blinds
  const rollerBlinds = nodes.find(filterByFileName(/^RollerBlinds/i)) || {};
  // external roller blinds
  const externalBlinds = nodes.find(filterByFileName(/externalRollerBlinds/i)) || {};
  // security shutters
  const securityShutters = nodes.find(filterByFileName(/securityShutters/i)) || {};
  // sectional doors
  const sectionalDoors = nodes.find(filterByFileName(/sectionalDoors/i)) || {};
  // interior doors
  const interiorDoors = nodes.find(filterByFileName(/interiorDoors/i)) || {};
  // sunshades
  const sunshades = nodes.find(filterByFileName(/sunshades/i)) || {};
  // sunshades
  // const thirdPartyLicenses = nodes.find(filterByFileName(/thirdPartyLicenses/i)) || {};

  // sections part
  const sectionsNodes = nodes
    .filter(filterByDirectoryName(/sections/i))
    .filter(filterByFileName(/^((?!(Team|About|Clients)).)*$/i));

  // anchors for NavBar
  const anchors = sectionsNodes.map(path(["frontmatter", "anchor"])).filter(identity);

  return {
    topNode,
    navBarNode,
    footerNode,
    sectionsNodes,
    anchors,
    gallery,
    pvcWindows,
    frontDoors,
    verticalBlinds,
    rollerBlinds,
    externalBlinds,
    securityShutters,
    sectionalDoors,
    interiorDoors,
    aluminiumWindows,
    sunshades,
  };
}
